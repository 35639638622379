import { useCallback, useState } from 'react';
import { Task, TaskCategory } from '../components/TaskList/TaskList';

export const useCore = () => {
	const [tasks, setTasks] = useState([] as Task[]);
	const [taskCategories, setTasksCategories] = useState([] as TaskCategory[]);

	const addTask = useCallback((task: Task) => {
		setTasks(tasks => [...tasks, task]);
	}, []);

	const deleteTask = useCallback((task: Task) => {
		setTasks(tasks => tasks.filter((t) => t.id !== task.id));
	}, []);

	const updateTask = useCallback((task: Task) => {
		setTasks((tasks) => {
			console.log('here', task);
			const taskListClone = [...tasks];
			const index = taskListClone.findIndex((t) => t.id === task.id);
			taskListClone[index] = task;

			console.log('here 2', taskListClone);
			
			return taskListClone;
		});
	}, []);

	const updateTasks = useCallback((tasks: Task[]) => {
        setTasks(tasks);
    }, [])

	const addCategory = useCallback((category: TaskCategory) => {
		setTasksCategories([...taskCategories, category]);
	}, [taskCategories]);

	const deleteCategory = useCallback((category: TaskCategory) => {
		setTasksCategories(taskCategories.filter((t) => t.id !== category.id));
	}, [taskCategories]);

	const updateCategory = useCallback((taskCategory: TaskCategory) => {
		setTasksCategories(
			taskCategories.map((tc) =>
				tc.id === taskCategory.id ? taskCategory : tc
			)
		);
	}, [taskCategories]);

	const updateCategories = useCallback((taskCategories: TaskCategory[]) => {
		setTasksCategories(taskCategories);
	}, []);

	return {
		tasks,
		updateTask,
		updateTasks,
		addTask,
		deleteTask,
		taskCategories,
		updateCategory,
		updateCategories,
		deleteCategory,
		addCategory
	};
};
