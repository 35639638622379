import { createContext } from 'react';
import { Task, TaskCategory } from '../components/TaskList/TaskList';

export interface TaskContainer {
}

export interface TaskCategoryContainer {
}

// define the core context interface for our app
// todo rename thing to something better
export interface CoreContextType {
    // todo: make this a more complex object when i can
	tasks: Task[];
	updateTask: (task: Task) => void;
	updateTasks: (tasks: Task[]) => void;
	addTask: (task: Task) => void;
	deleteTask: (task: Task) => void;

    // todo: make this a more complex object when i can
	taskCategories: TaskCategory[];
	updateCategory: (category: TaskCategory) => void;
	updateCategories: (categories: TaskCategory[]) => void;
	deleteCategory: (category: TaskCategory) => void;
	addCategory: (category: TaskCategory) => void;
}

// define the default values for our context
const DEFAULT_CORE_CONTEXT: CoreContextType = {
		tasks: [],
		updateTask: (task: Task) => {},
		updateTasks: (tasks: Task[]) => {},
		addTask: (task: Task) => {},
		deleteTask: (task: Task) => {},
		taskCategories: [],
		updateCategory: (category: TaskCategory) => {},
		updateCategories: (categories: TaskCategory[]) => {},
		deleteCategory: (category: TaskCategory) => {},
		addCategory: (category: TaskCategory) => {}
};

// create the context
export const CoreContext = createContext<CoreContextType>(DEFAULT_CORE_CONTEXT);
