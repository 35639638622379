export interface TaskProps {
	tasks: Task[];
}

export interface Task {
	id: string;
	name: string;
	description: string;
	status: TaskStatus;
	categoryId: string;
}

export enum TaskStatus {
	INCOMPLETE = 0,
	IN_PROGRESS = 1,
	COMPLETE = 2
}

export interface TaskCategory {
	name: string;
	id: string;
}
export const TaskList = ({tasks, taskCategories}: {tasks: Task[], taskCategories: TaskCategory[]}) => {
	return (
		<>
			<h2>Incomplete Tasks</h2>
			{tasks.some((t) => t.status !== TaskStatus.COMPLETE) ? (
				<>
					<ul>
						{tasks.map((task: Task) => {
							if (task.status !== TaskStatus.COMPLETE) {
								return (
									<li key={task.id}>
										{task.name} -{' '}
										{taskCategories.find((tc) => tc.id === task.categoryId)
											?.name ?? 'No Category'}
										<button
											type="button"
											onClick={() =>
												(tasks.find((t) => t.id === task.id)!.status =
													TaskStatus.COMPLETE)
											}>
											Complete
										</button>
									</li>
								);
							}
							return null;
						})}
					</ul>
				</>
			) : (
				<p>It looks like you've got nothing to do</p>
			)}
			{tasks.some((t) => t.status === TaskStatus.COMPLETE) ? (
				<>
					<h2>Completed Tasks</h2>
					<ul>
						{tasks.map((task: Task) => {
							if (task.status === TaskStatus.COMPLETE) {
								return (
									<li key={task.id}>
										{task.name} -{' '}
										{taskCategories.find((tc) => tc.id === task.categoryId)
											?.name ?? 'No Category'}
										<button
											type="button"
											onClick={() =>
												(tasks.find((t) => t.id === task.id)!.status =
													TaskStatus.INCOMPLETE)
											}>
											Undo
										</button>
									</li>
								);
							}
							return null;
						})}
					</ul>
				</>
			) : null}
		</>
	);
};
