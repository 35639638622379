import { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCheck,
	faRotateLeft,
	faPen,
	faHashtag
} from '@fortawesome/free-solid-svg-icons';
import { Task, TaskStatus } from './TaskList';
import { CoreContext } from '../../state/app.context';

export const TaskListItem =  ({ task }: { task: Task }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [shouldSlideAway, setShouldSlideAway] = useState(false);
	const appContext = useContext(CoreContext);

	const setTaskStatus = (task: Task) => {
		// add the slide-out-right class to the li
		setShouldSlideAway(true);

		// wait for the animation to finish
		setTimeout(() => {
			//update the state with the new status
			const newStatus =
				task.status !== TaskStatus.COMPLETE
					? TaskStatus.COMPLETE
					: TaskStatus.INCOMPLETE;
			appContext.updateTask({ ...task, status: newStatus });
			setShouldSlideAway(false);
		}, 400);
	};

	return (
		<li
			key={task.id}
			className={`c-task-list--item ${
				shouldSlideAway ? 'slide-out-right' : ''
			} ${
				task.status === TaskStatus.COMPLETE ? 'c-task-list--item__complete' : ''
			}`}>
			<div className="c-task-list--item-status">
				<button
					className="c-task-list--item-status-update"
					onClick={() => setTaskStatus(task)}>
					<FontAwesomeIcon
						icon={task.status !== TaskStatus.COMPLETE ? faCheck : faRotateLeft}
						aria-label={
							task.status !== TaskStatus.COMPLETE
								? 'Mark Task as incomplete'
								: 'Mark Task as complete'
						}
						style={{ color: '#878787' }}
					/>
				</button>
			</div>
			<div className="c-task-list--item-content">
				<p className="c-task-list--item-title">
					<span className='c-task-list--item-title-items'>{task.name}</span>
					<span className="c-pill__small c-task-list--item-title-items">
							<FontAwesomeIcon icon={faHashtag} />
							{
								appContext.taskCategories.find(
									(tc) => tc.id === task.categoryId
								)?.name ?? 'No Category'
							}
					</span>

					<button className='c-task-list--item-title-items' aria-label="Edit Task">
						<FontAwesomeIcon icon={faPen} style={{ color: '#878787' }} />
					</button>
				</p>
				<div className="c-task-list--item-description">
					<button
						className={`c-task-list--item-description-show-hide ${
							isOpen ? 'open' : ''
						}`}
						onClick={() => setIsOpen(!isOpen)}
						aria-label={isOpen ? 'Show Less' : 'Show More'}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							height="1em"
							viewBox="0 0 512 512">
							<path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
						</svg>
					</button>
					{isOpen
						? task.description
						: `${task.description.substring(0, 100)} …`}
				</div>
			</div>
		</li>
	);
};
