import { ChangeEvent, FormEvent, useContext, useState } from 'react';
import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { CoreContext } from '../../../state/app.context';
import { Task, TaskStatus } from '../../TaskList/TaskList';

export const AddNewTask = () => {
	const coreContext = useContext(CoreContext);

	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [categoryId, setCategoryId] = useState('');

	return (
		<div className="c-container">
			<form
				className="c-form"
				onSubmit={(event: FormEvent) => {
					event.preventDefault();

					coreContext.addTask({
						id: Date.now().toString(),
						name,
						status: TaskStatus.INCOMPLETE,
						description,
						categoryId
					} as Task);

					// clear the form
					setName('');
                    setDescription('');
                    setCategoryId('');
				}}>
				<h2>Add a new Task</h2>
				<TextField
					id="task-name"
					label="Name"
					value={name}
					fullWidth
					required
					margin="normal"
					onChange={(event: ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
				/>

				<FormControl fullWidth margin="normal">
					<InputLabel id="demo-simple-select-label">Category</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={categoryId}
						label="Category"
						required
						onChange={(event) => setCategoryId(event.target.value)}>
						{!!coreContext &&
							coreContext.taskCategories.map((category) => (
								<MenuItem key={category.id} value={category.id}>
									{category.name}
								</MenuItem>
							))}
					</Select>
				</FormControl>

				<TextField
					id="task-description"
					label="Description"
					value={description}
					fullWidth
					required
					margin="normal"
					onChange={(event: ChangeEvent<HTMLInputElement>) => setDescription(event.target.value)}
				/>

				<Button
					type="submit"
					variant="contained"
					endIcon={<FontAwesomeIcon icon={faPlus} />}>
					Add
				</Button>
			</form>
		</div>
	);
};
