import React, { useEffect } from 'react';
import './App.css';
import { CoreContext } from './state/app.context';
import { useCore } from './hooks/useCore';
import { ErrorBoundary } from './components/ErrorBoundry';
import { Header } from './components/Header/Header';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './components/Pages/Home/Home';
import { AddNewTask } from './components/Pages/AddTask/AddNewTask';
import { Task, TaskCategory, TaskStatus } from './components/TaskList/TaskList';

const mockTasks = [
	{
		id: '1',
		name: 'task 1',
		status: TaskStatus.INCOMPLETE,
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac metus ante. Suspendisse eu sem quis metus condimentum sagittis et nec nibh. Aliquam erat volutpat. Ut non interdum quam, sed laoreet erat. Donec id quam eleifend, mattis dui non, vestibulum felis. Nullam volutpat gravida justo in efficitur.',
		categoryId: 'work-id'
	},
	{
		id: '2',
		name: 'task 2',
		status: TaskStatus.INCOMPLETE,
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac metus ante. Suspendisse eu sem quis metus condimentum sagittis et nec nibh. Aliquam erat volutpat. Ut non interdum quam, sed laoreet erat. Donec id quam eleifend, mattis dui non, vestibulum felis. Nullam volutpat gravida justo in efficitur.',
		categoryId: 'workout-id'
	},
	{
		id: '3',
		name: 'task 3',
		status: TaskStatus.INCOMPLETE,
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac metus ante. Suspendisse eu sem quis metus condimentum sagittis et nec nibh. Aliquam erat volutpat. Ut non interdum quam, sed laoreet erat. Donec id quam eleifend, mattis dui non, vestibulum felis. Nullam volutpat gravida justo in efficitur.',
		categoryId: 'training-id'
	},
	{
		id: '4',
		name: 'task 4',
		status: TaskStatus.INCOMPLETE,
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac metus ante. Suspendisse eu sem quis metus condimentum sagittis et nec nibh. Aliquam erat volutpat. Ut non interdum quam, sed laoreet erat. Donec id quam eleifend, mattis dui non, vestibulum felis. Nullam volutpat gravida justo in efficitur.',
		categoryId: 'cooking-id'
	},
	{
		id: '5',
		name: 'task 5',
		status: TaskStatus.INCOMPLETE,
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac metus ante. Suspendisse eu sem quis metus condimentum sagittis et nec nibh. Aliquam erat volutpat. Ut non interdum quam, sed laoreet erat. Donec id quam eleifend, mattis dui non, vestibulum felis. Nullam volutpat gravida justo in efficitur.',
		categoryId: 'chores-id'
	},
	{
		id: '6',
		name: 'task 6',
		status: TaskStatus.INCOMPLETE,
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac metus ante. Suspendisse eu sem quis metus condimentum sagittis et nec nibh. Aliquam erat volutpat. Ut non interdum quam, sed laoreet erat. Donec id quam eleifend, mattis dui non, vestibulum felis. Nullam volutpat gravida justo in efficitur.',
		categoryId: 'work-id'
	}
] as Task[];


const mockTaskCategories = [
	{ name: 'work', id: 'work-id' },
	{ name: 'workout', id: 'workout-id' },
	{ name: 'training', id: 'training-id' },
	{ name: 'cooking', id: 'cooking-id' },
	{ name: 'chores', id: 'chores-id' }
] as TaskCategory[];

function App() {
	const coreContext = useCore();

	useEffect(() => {
		coreContext.updateTasks(mockTasks);
		coreContext.updateCategories(mockTaskCategories);
		 // eslint-disable-next-line
	}, []);

	return (
		<CoreContext.Provider value={coreContext}>
			<ErrorBoundary fallback={<div>Loading...</div>}>
				<BrowserRouter>
					<Header />
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="add-new-task" element={<AddNewTask />} />
						<Route path="*" element={<h1>Not Found</h1>} />
					</Routes>
				</BrowserRouter>
			</ErrorBoundary>
		</CoreContext.Provider>
	);
}

export default App;
