import React, { useState } from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faHouse,
	faCheckDouble,
	faPlus,
	faCalendarDays,
	faBars
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export const Header = () => {
	const [isOpen, setState] = useState(false);
	const toggleDrawer =
		(open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
			if (
				event.type === 'keydown' &&
				((event as React.KeyboardEvent).key === 'Tab' ||
					(event as React.KeyboardEvent).key === 'Shift')
			) {
				return;
			}

			setState(open);
		};

	const list = (links: MenuLink[]) => (
		<Box
			sx={{ width: 250 }}
			role="presentation"
			onClick={toggleDrawer(false)}
			onKeyDown={toggleDrawer(false)}>
			<List>
				{links.map((link) => (
					<ListItem key={link.url}>
						<ListItemIcon>{link.icon}</ListItemIcon>
						<Link to={link.url} style={{ color: 'black' }}>
							<ListItemText primary={link.name} />
						</Link>
					</ListItem>
				))}
			</List>
		</Box>
	);

	const links: MenuLink[] = [
		{
			name: 'Home',
			url: '/',
			icon: <FontAwesomeIcon icon={faHouse} style={{ color: '#878787' }} />
		},
		{
			name: 'All Tasks',
			url: '/tasks',
			icon: (
				<FontAwesomeIcon icon={faCheckDouble} style={{ color: '#878787' }} />
			)
		},
		{
			name: 'Add a new Task',
			url: '/add-new-task',
			icon: <FontAwesomeIcon icon={faPlus} style={{ color: '#878787' }} />
		},
		{
			name: 'Calendar',
			url: '/calendar',
			icon: (
				<FontAwesomeIcon icon={faCalendarDays} style={{ color: '#878787' }} />
			)
		}
	];

	return (
		<header className="c-header">
			<h1 className="c-header--heading">Task Manager</h1>
			<React.Fragment>
				<IconButton onClick={toggleDrawer(true)}>
					<FontAwesomeIcon icon={faBars} style={{ color: '#878787' }} />
				</IconButton>
				<SwipeableDrawer
					anchor={'right'}
					open={isOpen}
					onClose={toggleDrawer(false)}>
					{list(links)}
				</SwipeableDrawer>
			</React.Fragment>
		</header>
	);
};

interface MenuLink {
	name: string;
	url: string;
	icon: JSX.Element;
}
