import { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { TaskListItem } from '../../TaskList/TaskListItem';
import { CoreContext } from '../../../state/app.context';

export const Home = () => {
	const coreContext = useContext(CoreContext);
	
	return (
		<div className={'c-container'}>
			<section className="c-today-list">
				<header className="c-today-list--heading">
					<h2 className="c-today-list--heading-title">TodayList</h2>
					<Link
						to={'/add-new-task'}
						className="c-today-list--heading-link"
						style={{ textDecoration: 'underline' }}>
						Add a Task
					</Link>
				</header>
				{useMemo(() => {
					return (
						<ul className="c-task-list">
							{[...coreContext.tasks]
								.sort((t1, t2) => {
									if (t1.status < t2.status) {
										return -1;
									} else {
										return 1;
									}
								})
								.map((task) => (
									<TaskListItem key={task.id} task={task} />
								))}
						</ul>
					);
				}, [coreContext.tasks])}
			</section>
		</div>
	);
};
